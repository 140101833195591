




import { Component, Vue } from "vue-property-decorator";
import { DefaPageError } from "@defa-as/components";

@Component({
  components: {
    DefaPageError,
  },
})
export default class ViewError extends Vue {}
